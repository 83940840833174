import request from '@/utils/request'

const ordersApi = {
  // get orders info
  base: '/api/v1',
  orders: '/api/v1/rest/orders',
  query: '/api/v1/order/query',
  findByKeyword: '/api/v1/rest/orders/search/findByKeyword',
  findByStatus: '/api/v1/rest/orders/search/findByStatus'
}

/**
 * list
 * @param params { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function orders (params) {
  let url = ordersApi.orders
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * add
 * @param params { name: '', developer: '', area: '', type: '' , time }
 * @returns {*}
 */
export function addOrders (params) {
  return request({
    url: ordersApi.orders,
    method: 'post',
    data: params
  })
}

/**
 * edit
 * @param params { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'patch',
    data: params
  })
}

/**
 * del
 * @param params { id: '' }
 * @returns {*}
 */
export function delOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'delete'
  })
}

/**
 * del order and report
 * @param params { id: '' }
 * @returns {*}
 */
export function deleteOrders (params) {
  return request({
    url: ordersApi.base + `/order/delete`,
    method: 'get',
    params
  })
}

/**
 * find
 * @param params { status: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findOrdersByStatus (params) {
  let url = ordersApi.findByStatus
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * find
 * @param params { status: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function queryOrders (params) {
  let url = ordersApi.query
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param params { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findOrders (params) {
  let url = ordersApi.findByKeyword
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param params { id: '' }
 * @returns {*}
 */
export function findByIdOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'get'
  })
}

/**
 * orders
 * @param params { id: '', name: '' }
 * @returns {*}
 */
export function inOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}/${params.name}`,
    method: 'get'
  })
}

/**
 * orders manage
 * @param params { id: '', remark: '' }
 * @returns {*}
 */

 export function finish(data) {
  let url = ordersApi.base + '/order/commission/finish'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
   return request({
     url,
     method: 'put'
   })
 }
 
/**
 * orders manage
 * @param params { id: '', remark: '' }
 * @returns {*}
 */

export function fail(data) {
  let url = ordersApi.base + '/order/commission/fail'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put'
  })
}
 
/**
 * orders manage
 * @param params { id: '', remark: '' }
 * @returns {*}
 */

export function back(data) {
  let url = ordersApi.base + '/order/money/back'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put',
    data
  })
}
 
/**
 * orders manage
 * @param params { id: '', commission: '', remark: '' }
 * @returns {*}
 */

export function money(data) {
  let url = ordersApi.base + '/order/money/finish'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put'
  })
}

/**
 * orders report
 * @param params id
 * @returns {*}
 */

export function orderReport(id) {
  const url = ordersApi.orders + `/${id}/report`
  return request({
    url,
    method: 'get'
  })
}

/**
 * orders report
 * @param params id
 * @returns {*}
 */

export function contractResident(id) {
  const url = ordersApi.orders + `/${id}/contractResident`
  return request({
    url,
    method: 'get'
  })
}

/**
 * orders conteactPhotos
 * @param params id
 * @returns {*}
 */

export function contractPhotos(id) {
  const url = ordersApi.orders + `/${id}/contractPhotos`
  return request({
    url,
    method: 'get'
  })
}

/**
 * orders conteactPhotos
 * @param params id
 * @returns {*}
 */

export function getPhotos(id) {
  const url = ordersApi.orders + `/${id}/photos`
  return request({
    url,
    method: 'get'
  })
}

/**
 * orders findByReportId
 * @param params
 * @returns {*}
 */

 export function findByReportId(reportId) {
  return request({
    url: ordersApi.orders + `/search/findByReportId?reportId=${reportId}`,
    method: 'get'
  })
 }

import request from '@/utils/request'

const orderLogsApi = {
  // get orderLogs info
  orderLogs: '/api/v1/rest/orderLogs',
  findByOrderId: '/api/v1/rest/orderLogs/search/findByOrderId'
}

/**
 * list
 * @param params { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function orderLogs(params = {}) {
  let url = orderLogsApi.orderLogs
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * list
 * @param id 
 * @returns {*}
 */
export function getOrderLogs(id) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'get'
  })
}

/**
 * list
 * @param id 
 * @returns {*}
 */
export function putOrderLogs(id, data) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'get',
    data
  })
}

/**
 * list
 * @param id 
 * @returns {*}
 */
export function patchOrderLogs(id, data) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'patch',
    data
  })
}

/**
 * list
 * @param id 
 * @returns {*}
 */
export function delOrderLogs(id) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'delete'
  })
}

/**
 * list
 * @param {orderId: '', sorted: true/false, unsorted: true/false }
 * @returns {*}
 */
export function findByOrderId(params) {
  let url = orderLogsApi.findByOrderId
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}
